<template>
  <div>
    <b-card header-tag="header" style="min-height: 750px;">
      <b-row>
        <b-col cols="12">
          <b-card v-if="d_selectedProjectData && d_selectedProjectData.data" no-body class="mb-1" header-tag="header" key="patient-diagnosis-list">
            <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
              <img src="@/icon/2930471.png" style="width: 1.5em;" /> {{ d_selectedProjectData.data.general.name.val }},
              <img src="@/icon/9370.png" style="width: 1.5em;" /> {{ d_selectedProjectData.created_by }}
              <img src="@/icon/2080590.png" style="width: 1.5em;" /> {{ DateFormat(d_selectedProjectData.created_at) }}
              <b-button style="margin-right: 5px;" class="pull-right" variant="secondary" @click="f_savePoleStarProject()">
                <img src="@/icon/2087726.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> kaydet
              </b-button>
              <b-button style="margin-right: 5px;" class="pull-right" variant="secondary" @click="f_goToProjectList()">
                <img src="@/icon/969174.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> proje listesi
              </b-button>
              <b-button style="margin-right: 5px;" class="pull-right" variant="secondary" @click="f_goToWisdomStats()">
                <img src="@/icon/890016.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> Wisdom Stats
              </b-button>
            </b-card-header>
            <b-navbar style="padding: 0px;">
              <b-nav pills>
                <b-nav-item @click="selected_wdm_group_type = 'details'" :active="selected_wdm_group_type === 'details'"> Detaylar </b-nav-item>
                <b-nav-item @click="selected_wdm_group_type = 'input_output'" :active="selected_wdm_group_type === 'input_output'"> Girdi & Çıktı Parametreleri </b-nav-item>
              </b-nav>
            </b-navbar>
            <b-card v-if="selected_wdm_group_type === 'details'" no-body class="mb-1" header-tag="header" style="border: solid 1px #20a8d8;">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                Proje detayları
                <b-button style="margin-right: 5px;" size="sm" class="pull-right" variant="secondary" @click="f_editProject()">
                  <img src="@/icon/2087726.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> düzenle
                </b-button>
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <wisdom-data-show :change="'0'" :view_mode="'table'" :data="d_selectedProjectData" :data_type="'wdm3'" :option_data="option_data['wdm3']"></wisdom-data-show>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-if="selected_wdm_group_type === 'input_output'" no-body class="mb-1" header-tag="header" key="patient-diagnosis-list">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                Girdi & Çıktı Parametreleri
                <b-dropdown class="pull-right">
                  <b-dropdown-item @click="f_createInputOutputText()"> <img src="@/icon/1624138.png" style="width: 2em;"> Makale Girdi & Çıktı Yazısını Üret </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <template v-if="column_list.length > 0">
                <div style="overflow-x: hidden; overflow-y: auto; height: 700px;">
                  <template v-for="(col, col_ind) in column_list">
                    <template v-if="col.type === 'wdm'">
                      <b-row :style="d_selectedColumnInd === col_ind ? 'margin: 0px; padding-right: 20px; background-color: #acebd5; cursor: pointer; border-bottom: solid 1px #8f9291;' : 'margin: 0pxx; padding-right: 20px; cursor: pointer; border-bottom: solid 1px #8f9291;'">
                        <b-col sm="9" @click="f_selectColumn(col_ind)">
                          <wdm-parameter-location :col="col" :loc="col.loc" :col_ind="col_ind" :wdm_name="col.wdm_name" :show_wdm_name_upper="false"></wdm-parameter-location>
                          <template v-if="col.date_ability && col.date_ability === 'yes'">
                            <img src="@/icon/2080590.png" class="img-rounded img-responsive" style="width: 1.5em;" title="Tarih ilişkisi kurulabilen bir parametredir." />
                          </template>
                        </b-col>
                        <b-col sm="3">
                          <b-form-select v-model="col.io">
                            <option value="">Seçim Yapınız...</option>
                            <option value="input">Girdi</option>
                            <option value="output">Çıktı</option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </template>
                    <template v-else>
                      <b-row :style="d_selectedColumnInd === col_ind ? 'margin: 0px; padding-right: 20px; background-color: #acebd5; cursor: pointer; border-bottom: solid 1px #8f9291;' : 'margin: 0px; padding: 5px; cursor: pointer; border-bottom: solid 1px #8f9291;'">
                        <b-col sm="9" @click="f_selectColumn(col_ind)">
                          <strong>{{ col_ind + 1 }} - [{{ col.type }}] </strong> {{ col.label }} [{{ col.param_type }}]
                        </b-col>
                        <b-col sm="3">
                          <b-form-select v-model="col.io">
                            <option value="">Seçim Yapınız...</option>
                            <option value="input">Girdi</option>
                            <option value="output">Çıktı</option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </div>
              </template>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'1500'">
      <h3 slot="header">Yeni Kutup Yıldızı Projesi Ekle</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="wisdomDataSave()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showNewColumnRuleModal" @close="d_showNewColumnRuleModal = false" :width="'1500'">
      <h3 slot="header">Yeni Kolon Kuralı Oluşturma</h3>
      <div slot="body">
        <b-row>
          <b-col cols="6">
            <b-card no-body class="mb-1" header-tag="header" key="patient-diagnosis-list">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark" style="height: 50px;">
                <b-row>
                  <b-col cols="6">
                    <strong>wdm parametre seçimi</strong>
                  </b-col>
                  <b-col cols="6">
                    <v-select :title="column_query_list.length > 0 ? 'Sorgu listesini temizlerseniz yeniden seçim yapabilirsiniz.' : ''" v-model="d_selectedWdmTypeData" :options="d_projectWdmList" :disabled="column_query_list.length > 0"></v-select>
                  </b-col>
                </b-row>
              </b-card-header>
              <br>
              <div v-if="d_selectedWdmTypeData && show_wisdom_data_model" style="overflow-x: hidden; overflow-y: auto; height: 700px;">
                <wisdom-data-modal :data="prepare_data" :data_type="d_selectedWdmTypeData.value" :option_data="selected_option" :rule_engine="true" :query_list="column_query_list" :wdmr_change="wdmr_change" :query_list_type="'one_query'" :polestar="true" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
              </div>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card no-body class="mb-1" header-tag="header" key="patient-diagnosis-list">
              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark" style="height: 50px;">
                <b-row>
                  <b-col cols="6">
                    <b-dropdown v-if="column_query_list.length > 0" variant="success" size="lg">
                      <b-dropdown-item @click="add_key_compile_str('and', 'column_rule')"> sorgular arasına "ve" bağlacını koyarak hepsini modele ekle </b-dropdown-item>
                    </b-dropdown>
                    <strong> Sorgu Listesi </strong>
                  </b-col>
                  <b-col cols="6"> </b-col>
                </b-row>
              </b-card-header>
              <br>
              <template v-if="column_query_list.length > 0">
                <div style="overflow-x: hidden; overflow-y: auto; height: 700px;" v-if="d_showRuleQuery">
                  <rule-query :query_list="column_query_list" :compile_str="column_compile_str_array" :mode="'edit'" :polestar="true"></rule-query>
                </div>
                <rule-compile-str :compile_str_array="column_compile_str_array" :mode="'edit'" :show_caption="'sorgu'" :acordion_index="'rule-1'"></rule-compile-str>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_addEditColumnRule()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showNewColumnRuleModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as WdmParameterLocation
} from '@/components/widgets/WdmParameterLocation';
import {
  default as RuleCompileStr
} from '@/components/widgets/RuleCompileStr';
import {
  default as RuleQuery
} from '@/components/widgets/RuleQuery';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { mapGetters } from 'vuex';
import OptionService from '@/services/option';
import GlobalService from '@/services/global';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import vSelect from 'vue-select';
import WebService from '@/services/webservice';
import PoleStarService from '@/services/polestar';
//
import HospitalService from '@/services/hospital';
import { DateFunctions } from '@/services/public/date_functions';
import moment from 'moment';
import FileSaver from 'file-saver';
export default {
  name: 'PoleStarArticle',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    vSelect,
    Modal,
    WisdomDataModal,
    WisdomDataShow,
    RuleQuery,
    RuleCompileStr,
    WdmParameterLocation
  },
  props: {},
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      d_selectedDateRelation: { 'col_ind': '', 'op': '' },
      d_selectedTreatmentCreatedBy: '',
      d_selectedTreatmentStatus: '',
      d_selectedTreatmentReason: '',
      d_selectedDrug: '',
      d_showNewColumnRuleModal: false,
      d_selectedColumnInd: '',
      column_list: [],
      wdmr_change: { 'rule': 0 },
      show_wisdom_data_model: false,
      parameter_design_full_screen: false,
      d_projectWdmList: [],
      prepare_data: { 'data': {} },
      compile_str_array: [],
      query_list: [],
      column_compile_str_array: [],
      column_query_list: [],
      selected_option: {},
      d_selectedProjectInd: '',
      d_selectedWdmType: '',
      d_selectedWdmVersion: '',
      selected_wdm_group_type: 'details',
      wisdom_data: {},
      // seçeneklerde olabilecekler veritabanından analiz edilerek çekiliyor.
      d_statisticalParameterOptions: {
        'diagnosis_main_group_code': [],
        'diagnosis_sub_group_code': [],
        'treatment_status': [{ 'label': 'Aktif Tedavi', 'value': 'active' }, { 'label': 'Sonlandırılmış Tedavi', 'value': 'terminated' }],
        'hospital_list': [],
        'drugs_in_treatment': require('@/options/favorite_drugs').options,
        'treatment_reason': require('@/options/treatment_reason').options,
        'gender': [{ 'label': 'Erkek', 'value': '0' }, { 'label': 'Kadın', 'value': 1 }]
      },
      d_selectedStatisticalModel: '',
      d_selectedStatisticalModelGroup: 'my_hospital',
      d_prepared_columns: require('@/options/prepared_columns').options,
      d_addedModels: [],
      d_expandAllCollapsed: true,
      d_showTable: true,
      d_hospitalGroupList: [],
      user: {},
      option_data: {},
      wisdom_op: 'new',
      data_type: '',
      show_wisdom_data_modal: false,
      d_selectedProjectData: '',
      d_projectList: [],
      wdm_version: '',
      d_selectedWdmTypeData: '',
      d_showRuleQuery: true,
      d_selectedProjectAnalyze: { 'excel_file': '', 'case_rows': [], 'case_columns': [] },
      column_rule_operation_type: 'new',
      d_selectedColumnRuleInd: '',
      d_selectedColumnList: [],
      d_columnSelectionType: 'wdm_columns',
      d_allUsers: [],
      d_preparedText: ''
    };
  },
  created: function () {
    this.wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
    this.user = JSON.parse(localStorage.getItem('user'));
    let x = JSON.parse(localStorage.getItem('all_users'));
    for (let i in x) {
      this.d_allUsers.push({ 'label': x[i].first_name + ' ' + x[i].last_name, 'value': x[i].username });
    }
    let query = 'data_type=wdm3&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm3'] = resp.data.result;
          if (this.$route.query.project_id) {
            let key = 'wdm3_' + this.$route.query.project_id;
            GlobalService.get_one_cb_document({ 'key': key, 'bucket': 'polestar' })
              .then(resp => {
                if (resp.data.status === 'success') {
                  this.d_selectedProjectData = resp.data.result;
                  if (this.d_selectedProjectData.design) {
                    this.column_list = this.d_selectedProjectData.design.column_list;
                  }
                  for (let i in this.d_selectedProjectData['data']['general']['wdm_version_list']['list']) {
                    let x = {
                      'group': this.d_selectedProjectData['data']['general']['wdm_version_list']['list'][i]['wdm_group']['val']['value'],
                      'label': this.d_selectedProjectData['data']['general']['wdm_version_list']['list'][i]['wdm_type']['val']['label'],
                      'value': this.d_selectedProjectData['data']['general']['wdm_version_list']['list'][i]['wdm_type']['val']['value'],
                      'key': this.d_selectedProjectData['data']['general']['wdm_version_list']['list'][i]['wdm_version']['val']['value']
                    };
                    this.d_projectWdmList.push(JSON.parse(JSON.stringify(x)));
                  }
                } else {
                  alert('error ', resp.data.message);
                }
              });
          }
        } else {
          console.log('errror ', resp.data.message);
        }
      });
    // this.f_getProjectList();
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_createInputOutputText: function () {
      let input_txt = '';
      let output_txt = '';
      for (let i in this.column_list) {
        if (this.column_list[i].io && this.column_list[i].io === 'output') {
          output_txt += this.column_list[i].label + ', ';
        } else if (this.column_list[i].io && this.column_list[i].io === 'input') {
          input_txt += this.column_list[i].label + ', ';
        }
      }
      if (input_txt && output_txt) {
        this.d_preparedText = input_txt + ' verilerinin ' + output_txt + ' verileri üzerine etkisi değerlendirilmesi amaçlanmıştır.';
        if (!this.d_selectedProjectData.data) {
          this.d_selectedProjectData.data = {};
        }
        if (!this.d_selectedProjectData.data.article_summary) {
          this.d_selectedProjectData.data.article_summary = {};
        }
        if (!this.d_selectedProjectData.data.article_summary.purpose) {
          this.d_selectedProjectData.data.article_summary.purpose = { 'val': '' };
        }
        this.d_selectedProjectData.data.article_summary.purpose.val = this.d_preparedText;
        alert('Proje özet amaç bölümüne eklenmiştir.');
      } else {
        alert('lütfen girdi ve çıktı parametrelerini en az birer adet seçiniz.');
      }
    },
    f_controlDateEligibility: function (selected_col_ind, target_col_ind) {
      if (!this.column_list[target_col_ind].data_selection || (this.column_list[target_col_ind].data_selection && ['2', '3'].indexOf(this.column_list[target_col_ind].data_selection) === -1)) {
        return false;
      }
      if (selected_col_ind !== target_col_ind) {
        if (!this.column_list[target_col_ind].date_ability || (this.column_list[target_col_ind].date_ability && this.column_list[target_col_ind].date_ability !== 'yes')) {
          return false;
        }
        // seçili kolon başka bir kolonun içerisinde kullanıldıysa da o kolon tarih ilişkisi seçilemez
        for (let i in this.column_list) {
          if (this.column_list[i].date_relation) {
            for (let d in this.column_list[i].date_relation) {
              if (this.column_list[i].date_relation[d].col_ind === selected_col_ind && parseInt(i) === target_col_ind) {
                return false;
              }
            }
          }
        }
      } else {
        return false;
      }
      return true;
    },
    f_addDateRelation: function () {
      if (!this.column_list[this.d_selectedColumnInd].date_relation) {
        this.column_list[this.d_selectedColumnInd].date_relation = [];
      }
      let x = { 'op': this.d_selectedDateRelation.op, 'col_ind': this.d_selectedDateRelation.col_ind };
      let det = false;
      for (let i in this.column_list[this.d_selectedColumnInd].date_relation) {
        if (this.column_list[this.d_selectedColumnInd].date_relation[i].col_ind === x.col_ind) {
          det = true;
          this.column_list[this.d_selectedColumnInd].date_relation[i] = x;
        }
      }
      if (!det) {
        this.column_list[this.d_selectedColumnInd].date_relation.push(x);
      }
      this.$forceUpdate();
    },
    f_deleteAllColumns: function () {
      if (this.d_selectedProjectData.design && this.d_selectedProjectData.design.column_list) {
        this.d_selectedColumnInd = '';
        this.d_selectedProjectData.design.column_list = [];
        this.column_list = [];
        this.$forceUpdate();
      }
    },
    f_showDetailsOnLeft: function (col_ind) {
      for (let i in this.d_prepared_columns[this.column_list[col_ind].type].list) {
        if (this.d_prepared_columns[this.column_list[col_ind].type].list[i].value === this.column_list[col_ind].value) {
          this.d_prepared_columns[this.column_list[col_ind].type].list[i] = JSON.parse(JSON.stringify(this.column_list[col_ind]));
          break;
        }
      }
      this.$forceUpdate();
    },
    f_addSelectedTreatmentCreatedBy: function (section_ind, list_ind) {
      for (let i in this.d_prepared_columns[section_ind]['list'][list_ind].filter.created_by) {
        if (this.d_prepared_columns[section_ind]['list'][list_ind].filter.created_by[i].value === this.d_selectedTreatmentCreatedBy.value) {
          return;
        }
      }
      this.d_prepared_columns[section_ind]['list'][list_ind].filter.created_by.push(this.d_selectedTreatmentCreatedBy);
    },
    f_addSelectedTreatmentStatus: function (section_ind, list_ind) {
      for (let i in this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_status) {
        if (this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_status[i].value === this.d_selectedTreatmentStatus.value) {
          return;
        }
      }
      this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_status.push(this.d_selectedTreatmentStatus);
    },
    f_addSelectedTreatmentReason: function (section_ind, list_ind) {
      for (let i in this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_reason) {
        if (this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_reason[i].value === this.d_selectedTreatmentReason.value) {
          return;
        }
      }
      this.d_prepared_columns[section_ind]['list'][list_ind].filter.treatment_reason.push(this.d_selectedTreatmentReason);
    },
    f_addSelectedDrugToTreatmentList: function (section_ind, list_ind) {
      for (let i in this.d_prepared_columns[section_ind]['list'][list_ind].filter.selected_drugs) {
        if (this.d_prepared_columns[section_ind]['list'][list_ind].filter.selected_drugs[i].value === this.d_selectedDrug.value) {
          return;
        }
      }
      this.d_prepared_columns[section_ind]['list'][list_ind].filter.selected_drugs.push(this.d_selectedDrug);
    },
    f_addPreparedColumn: function (column) {
      this.column_list.push(column);
      this.$forceUpdate();
    },
    f_addRuleToSelectedColumns: function (colrule_ind) {
      // ilgili rule içerisindeki veri tipinin ilgili kolon ile aynı olup olmadığını kontrol edip ekliyoruz.
      let data_type = this.d_selectedProjectData.rule.column_rule.list[colrule_ind].query_list[0].type.value;
      if (this.d_selectedColumnList.length > 0) {
        for (let i in this.d_selectedColumnList) {
          if (data_type === this.column_list[this.d_selectedColumnList[i]].wdm_type) {
            this.column_list[this.d_selectedColumnList[i]].rule_ind = colrule_ind;
          }
        }
      } else {
        alert('Seçili kolon yok. Lütfen kolon seçimi yapınız.');
      }
      this.$forceUpdate();
    },
    f_deleteColumnRule: function (colrule_ind) {
      // len = 1, 0. index siliniyor. Hepsinden temizle
      // len = >1 
      // 0. index siliniyor ise 0 olan kolonlar temizlenecek. 0dan büyük olan kolonların rule_ind 1 azaltılacak
      this.d_selectedProjectData.rule.column_rule.list.splice(colrule_ind, 1);
      for (let i in this.column_list) {
        if (this.d_selectedProjectData.rule.column_rule.list.length === 0) {
          if (this.column_list[i].rule_ind !== undefined) {
            this.$delete(this.column_list[i], 'rule_ind');
          }
        } else {
          if (this.column_list[i].rule_ind > colrule_ind) {
            this.column_list[i].rule_ind -= 1;
          } else if (this.column_list[i].rule_ind === colrule_ind) {
            this.$delete(this.column_list[i], 'rule_ind');
          }
        }
      }
      this.column_compile_str_array = [];
      this.column_query_list = [];
      this.$forceUpdate();
    },
    f_addColumnIndToList: function (colrule_ind) {
      if (this.d_selectedColumnList.indexOf(colrule_ind) === -1) {
        this.d_selectedColumnList.push(colrule_ind);
      } else {
        this.d_selectedColumnList.splice(this.d_selectedColumnList.indexOf(colrule_ind), 1);
      }
      this.$forceUpdate();
    },
    f_selectColumnRule: function (colrule_ind) {
      this.d_selectedColumnRuleInd = colrule_ind;
      this.column_compile_str_array = this.d_selectedProjectData.rule.column_rule.list[colrule_ind].compile_str_array;
      this.column_query_list = this.d_selectedProjectData.rule.column_rule.list[colrule_ind].query_list;
      this.$forceUpdate();
    },
    f_showColumnRuleModal: function (type, column_ind = '') {
      this.column_rule_operation_type = type;
      if (type === 'new') {
        this.column_query_list = [];
        this.column_compile_str_array = [];
      } else if (type === 'edit') {
        this.column_query_list = JSON.parse(JSON.stringify(this.column_list[column_ind].rule.query_list));
        let data_type = this.column_query_list[0].type.value;
        for (let i in this.d_projectWdmList) {
          if (this.d_projectWdmList[i].value === data_type) {
            this.d_selectedWdmTypeData = this.d_projectWdmList[i];
          }
        }
        this.column_compile_str_array = JSON.parse(JSON.stringify(this.column_list[column_ind].rule.compile_str_array));
      }
      this.d_showNewColumnRuleModal = true;
    },
    f_addEditColumnRule: function () {
      let x = {
        'compile_str': '',
        'compile_str_array': JSON.parse(JSON.stringify(this.column_compile_str_array)),
        'query_list': JSON.parse(JSON.stringify(this.column_query_list))
      };
      x.compile_str = this.create_compile_str(x.compile_str_array);
      if (this.column_rule_operation_type === 'edit') {
        this.column_list[this.d_selectedColumnInd].rule = x;
      } else {
        let det = false;
        let column_field_data = '';
        let wdm_name = '';
        let wdm_type = '';
        for (let i in this.column_query_list) {
          for (let f in this.column_query_list[i].fields) {
            if (this.column_query_list[i].fields[f].is_column) {
              det = true;
              column_field_data = this.column_query_list[i].fields[f];
              wdm_name = this.column_query_list[i].type;
              wdm_type = this.column_query_list[i].type.value;
              break;
            }
          }
          if (det) {
            break;
          }
        }
        let col_data = {
          'loc': column_field_data.loc,
          'type': 'wdm',
          'wdm_type': wdm_type,
          'wdm_name': wdm_name,
          'param_type': column_field_data.value_type,
          'date_ability': this.selected_option[wdm_type].date_ability ? this.selected_option[wdm_type].date_ability : '',
          'date_ability_limit': this.selected_option[wdm_type].date_ability_limit ? this.selected_option[wdm_type].date_ability_limit : '',
          'date_ability_type': this.selected_option[wdm_type].date_ability_type ? this.selected_option[wdm_type].date_ability_type : '',
          'record_count': this.selected_option[wdm_type].record_count ? this.selected_option[wdm_type].record_count : '',
          'rule': x
        };
        this.column_list.push(col_data);
      }
      this.d_showNewColumnRuleModal = false;
      this.$forceUpdate();
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    f_goToProjectList: function () {
      this.$router.push({ path: '/polestar/projects' });
    },
    f_goToWisdomStats: function () {
      this.$router.push({ path: '/polestar/wisdomstats', query: { project_id: this.d_selectedProjectData.id } });
    },
    f_scrollCaseTable: function () {
      var content = document.getElementById("case-table");
      // var scrolled = document.scrollingElement.scrollTop;
      // console.log('scrolled', scrolled);
      var position = content.offsetTop;
      var scrollHeight = content.scrollHeight;
      console.log('position', position);
      console.log('scrollHeight', scrollHeight);
    },
    f_downloadExcelFile: function (file) {
      FileSaver.saveAs(file, "cases.xlsx");
    },
    f_getCases: function () {
      if (this.d_selectedProjectData.design && this.d_selectedProjectData.design.column_list && this.d_selectedProjectData.design.column_list.length > 0) {
        let data = {
          'project': this.d_selectedProjectData,
          'hospital_list': [],
          'hospital_type': 'all',
          'user_type': 'all'
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Vaka listeniz hazırlanıyor. Lütfen bekleyiniz.' } });
        PoleStarService.get_project_cases(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.d_selectedProjectAnalyze.case_rows = resp.data.result.case_rows;
              this.d_selectedProjectAnalyze.case_columns = resp.data.result.case_columns;
              this.d_selectedProjectAnalyze.excel_file = resp.data.result.excel_file;
              // FileSaver.saveAs(this.d_selectedProjectAnalyze.excel_file, "xxx.xlsx");
            } else {
              let msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İstatistik için verilerin hazırlanması işlemi' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Lütfen kolon listenizi hazırlayınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İstatistik için verilerin hazırlanması işlemi' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_selectColumn: function (col_ind) {
      this.d_selectedColumnInd = col_ind;
      this.$forceUpdate();
    },
    f_deleteColumn: function (col_ind) {
      this.column_list.splice(col_ind, 1);
      this.$forceUpdate();
    },
    add_key_compile_str: function (key, type) {
      if (type === 'column_rule') {
        let list_count = 0;
        this.column_compile_str_array = [];
        list_count = this.column_query_list.length;
        this.column_compile_str_array.push('(');
        for (let i = 0; i < list_count; i++) {
          this.column_compile_str_array.push(i);
          if (list_count - 1 !== i) {
            this.column_compile_str_array.push(key);
          }
        }
        this.column_compile_str_array.push(')');
      } else if (type === 'main_rule') {
        let list_count = 0;
        this.compile_str_array = [];
        list_count = this.query_list.length;
        this.compile_str_array.push('(');
        for (let i = 0; i < list_count; i++) {
          this.compile_str_array.push(i);
          if (list_count - 1 !== i) {
            this.compile_str_array.push(key);
          }
        }
        this.compile_str_array.push(')');
      }
      this.$forceUpdate();
    },
    create_compile_str: function (compile_str_array) {
      let str = '';
      for (let i in compile_str_array) {
        if (['(', ')', 'and', 'or'].indexOf(compile_str_array[i]) === -1) {
          str = str + '-' + compile_str_array[i] + '-';
        } else {
          str = str + compile_str_array[i];
        }
        if (i !== compile_str_array.length - 1) { str = str + ' '; }
      }
      return str;
    },
    f_savePoleStarProject: function () {
      let data = { 'data': this.d_selectedProjectData };
      PoleStarService.save_polestar_project(data).then(resp => {
        let msg = '';
        if (resp.data.status === "success") {
          this.d_selectedProjectData = resp.data.result.project;
          msg = 'Proje başarıyla kaydedildi.';
        } else {
          msg = resp.data.message;
        }
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        this.$forceUpdate();
      });
    },
    f_ruleQueryTrueFalse: function () {
      this.d_showRuleQuery = false;
      setTimeout(function () {
        this.d_showRuleQuery = true;
        this.$forceUpdate();
      }.bind(this), 500)
    },
    f_editProject: function () {
      this.wisdom_op = "edit";
      this.data_type = 'wdm3';
      this.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedProjectData));
      // yeni grup tanımlandığında problem oluşturuyor. Dolayısıyla yeni bir grup tanımlandıysa wdmde onu önce edit modda da olsa oluşturuyoruz.
      for (let i in this.option_data['wdm3'].param_group.param_order) {
        if (!this.wisdom_data['data'][this.option_data['wdm3'].param_group.param_order[i]]) {
          this.wisdom_data['data'][this.option_data['wdm3'].param_group.param_order[i]] = {};
        }
      }
      this.show_wisdom_data_modal = true;
    },
    f_selectThisWdm: function (wdm_type, version, wdm_type_data) {
      this.d_selectedWdmTypeData = wdm_type_data;
      this.d_selectedWdmType = wdm_type;
      this.d_selectedWdmVersion = version;
      this.$forceUpdate();
    },
    f_getProjectList: function () {
      let data = { 'bucket': 'hospital', 'data_type': 'wdm3', 'project': 1 };
      OptionService.get_wdm_records(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_projectList = resp.data.result;
          } else {
            this.d_projectList = [];
            console.log('errror ', resp.data.message);
          }
        });
    },
    wisdomDataSave: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      // barcode no wdm2 ya da wisdom_data_model_3 içerisinden değiştirilebilir.
      let barcode_no = '';
      data = { 'data': this.wisdom_data };
      OptionService.save_wdm_option(data).then(resp => {
        if (resp.data.status === "success") {
          this.show_wisdom_data_modal = false;
          this.d_selectedProjectData = resp.data.result;
          let det = false;
          for (let i in this.d_projectList) {
            if (this.d_projectList[i].id === this.d_selectedProjectData.id) {
              det = true;
              this.d_projectList[i] = this.d_selectedProjectData;
              break;
            }
          }
          if (!det) {
            this.d_projectList.push(this.d_selectedProjectData);
          }
        } else {
          this.d_selectedProjectData = '';
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });
    },
    add_new_wisdom_data: function (data_type) {
      this.data_type = data_type;
      this.wisdom_op = "new";
      let pre_data = { 'id': 'new', 'data': {}, 'type': data_type, 'reference_wdm': {}, 'parent': data_type };
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      if (this.option_data[this.data_type]['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      }
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      this.wisdom_data = pre_data;
      this.show_wisdom_data_modal = true;
    },
    f_deleteFromCombine: function (model_ind, param_ind, type, opt_ind, comb_ind) {
      if (type === 'first_value') {
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'] && this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].length > 0) {
          let x = this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'][0];
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['value'] = x.value;
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['label'] = x.label;
          if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['translation']) {
            this.$delete(this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind], 'translation');
          }
          if (x.translation) {
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['translation'] = x.translation;
          }
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].splice(0, 1);
          if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].length === 0) {
            this.$delete(this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind], 'combine_list');
          }
        }
      } else {
        this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].splice(comb_ind, 1);
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind]['combine_list'].length === 0) {
          this.$delete(this.d_addedModels[model_ind]['parameters'][param_ind]['options'][opt_ind], 'combine_list');
        }
      }
      this.$forceUpdate();
    },
    f_addAsCombineOption: function (model_ind, param_ind) {
      for (let i in this.d_addedModels[model_ind]['parameters'][param_ind]['options']) {
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].selected) {
          if (!this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list) {
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list = [];
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list.push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          } else {
            for (let k in this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list) {
              if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list[k].value === this.d_addedModels[model_ind]['parameters'][param_ind].selected.value) {
                return;
              }
            }
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].combine_list.push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          }
        }
      }
      this.$forceUpdate();
    },
    f_anySelectedOptionForCombine: function (param) {
      if (param.selected) {
        for (let i in param.options) {
          if (param.options[i].selected) {
            if (param.options[i].value !== param.selected.value) {
              return true;
            }
          }
        }
      }
      return false;
    },
    f_selectThisOption: function (model_ind, pr, opt_ind, type) {
      // console.log('model_ind', model_ind);
      // console.log('pr', pr);
      // console.log('opt_ind', opt_ind);
      for (let i in this.d_addedModels[model_ind]['parameters'][pr]['options']) {
        this.$delete(this.d_addedModels[model_ind]['parameters'][pr]['options'][i], 'selected');
      }
      this.d_addedModels[model_ind]['parameters'][pr]['options'][opt_ind].selected = 1;
      this.$forceUpdate();
    },
    f_analyzeWithModels: function () {
      for (let i in this.d_addedModels) {
        this.d_addedModels[i].rows = [];
        this.d_addedModels[i].columns = [];
        this.d_addedModels[i].file = '';
        if (this.d_addedModels[i].row.length === 0 || this.d_addedModels[i].column.length === 0) {
          alert('Lütfen istantistiksel analize başlamadan önce satır ve sütun değerlerini belirtiniz.');
          return;
        }
      }
      // this.d_showTable = false;
      let data = { 'models': this.d_addedModels };
      // console.log('before send', data);
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kutup Yıldızı istatistiksel modellerinizi analiz ediyor. Lütfen bekleyiniz.' } });
      PoleStarService.analyze_with_statistic_models(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            // console.log('resp', resp.data.result);
            for (let i in resp.data.result) {
              this.d_addedModels[i] = resp.data.result[i];
              for (let param_ind in this.d_addedModels[i]['parameters']) {
                // date_type sebepsiz yere undefined oluyor. Bu nedenle kontrol edip düzeltiyoruz.
                if (this.d_addedModels[i]['parameters']['type'] === 'date' && !this.d_addedModels[i]['parameters']['date_type']) {
                  this.d_addedModels[i]['parameters']['date_type'] = 'none';
                  this.d_addedModels[i]['parameters']['starting_at'] = '';
                  this.d_addedModels[i]['parameters']['ending_at'] = '';
                }
              }
            }
          } else {
            alert('Error', resp.data.message);
          }
          // this.d_showTable = true;
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_deleteParam: function (model_ind, param_ind) {
      if (this.d_addedModels[model_ind]['row'].indexOf(param_ind) !== -1) {
        this.d_addedModels[model_ind]['row'].splice(param_ind);
      }
      if (this.d_addedModels[model_ind]['column'].indexOf(param_ind) !== -1) {
        this.d_addedModels[model_ind]['column'].splice(param_ind);
      }
      this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = [];
      this.$forceUpdate();
    },
    f_deleteOption: function (model_ind, param_ind, option_index) {
      this.d_addedModels[model_ind]['parameters'][param_ind]['options'].splice(option_index, 1);
      if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'].length === 0) {
        if (this.d_addedModels[model_ind]['row'].indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind]['row'].splice(param_ind);
        }
        if (this.d_addedModels[model_ind]['column'].indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind]['column'].splice(param_ind);
        }
      }
      this.$forceUpdate();
    },
    f_filterWithSelectedValue: function (model_ind, param_ind, type) {
      if (type === 'filter') {
        let det = false;
        for (let f in this.d_addedModels[model_ind]['parameters'][param_ind]['filter']) {
          if (this.d_addedModels[model_ind]['parameters'][param_ind]['filter'][f].value === this.d_addedModels[model_ind]['parameters'][param_ind].selected.value) {
            det = true;
            alert('Bu parametre ekli görünmektedir.');
            return;
          }
        }
        if (!det) {
          this.d_addedModels[model_ind]['parameters'][param_ind]['filter'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          // row ya da columnda ekli ise temizliyoruz.
          // aynı anda bağımlı ya da bağımsız değişken olamaz.
          if (this.d_addedModels[model_ind].column.indexOf(param_ind) !== -1) {
            this.d_addedModels[model_ind].column.splice(param_ind, 1);
          }
          if (this.d_addedModels[model_ind].row.indexOf(param_ind) !== -1) {
            this.d_addedModels[model_ind].row.splice(param_ind, 1);
          }
          this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'filter';
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = [];
        }
      } else if (type === 'row') {
        this.d_addedModels[model_ind]['parameters'][param_ind]['filter'] = [];
        if (this.d_addedModels[model_ind].row.indexOf(param_ind) === -1) {
          this.d_addedModels[model_ind].row.push(param_ind);
        }
        if (this.d_addedModels[model_ind].column.indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind].column.splice(param_ind, 1);
        }
        this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'all';
        this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = JSON.parse(JSON.stringify(this.d_statisticalParameterOptions[param_ind]));
      } else if (type === 'row_selected') {
        this.d_addedModels[model_ind]['parameters'][param_ind]['filter'] = [];
        if (this.d_addedModels[model_ind].row.indexOf(param_ind) === -1) {
          this.d_addedModels[model_ind].row.push(param_ind);
        }
        if (this.d_addedModels[model_ind].column.indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind].column.splice(param_ind, 1);
        }
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] === 'selected') {
          let det = false;
          for (let i in this.d_addedModels[model_ind]['parameters'][param_ind]['options']) {
            if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].value === this.d_addedModels[model_ind]['parameters'][param_ind].selected.value) {
              det = true;
            }
          }
          if (!det) {
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          }
        } else {
          // alldan selecteda geçişte önce optionsı temizliyoruz.
          this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'selected';
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = [];
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
        }
      } else if (type === 'column') {
        this.d_addedModels[model_ind]['parameters'][param_ind]['filter'] = [];
        if (this.d_addedModels[model_ind].column.indexOf(param_ind) === -1) {
          this.d_addedModels[model_ind].column.push(param_ind);
        }
        if (this.d_addedModels[model_ind].row.indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind].row.splice(param_ind, 1);
        }
        this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'all';
        this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = JSON.parse(JSON.stringify(this.d_statisticalParameterOptions[param_ind]));
      } else if (type === 'column_selected') {
        this.d_addedModels[model_ind]['parameters'][param_ind]['filter'] = [];
        if (this.d_addedModels[model_ind].column.indexOf(param_ind) === -1) {
          this.d_addedModels[model_ind].column.push(param_ind);
        }
        if (this.d_addedModels[model_ind].row.indexOf(param_ind) !== -1) {
          this.d_addedModels[model_ind].row.splice(param_ind, 1);
        }
        if (this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] === 'selected') {
          let det = false;
          for (let i in this.d_addedModels[model_ind]['parameters'][param_ind]['options']) {
            if (this.d_addedModels[model_ind]['parameters'][param_ind]['options'][i].value === this.d_addedModels[model_ind]['parameters'][param_ind].selected.value) {
              det = true;
            }
          }
          if (!det) {
            this.d_addedModels[model_ind]['parameters'][param_ind]['options'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
          }
        } else {
          // alldan selecteda geçişte önce optionsı temizliyoruz.
          this.d_addedModels[model_ind]['parameters'][param_ind]['option_type'] = 'selected';
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'] = [];
          this.d_addedModels[model_ind]['parameters'][param_ind]['options'].push(JSON.parse(JSON.stringify(this.d_addedModels[model_ind]['parameters'][param_ind].selected)));
        }
      }
      this.$forceUpdate();
    },
    f_addStatisticalModel: function () {
      if (this.d_selectedStatisticalModel) {
        // eklerken aynı modeli birden fazla parametre kombinasyonuyla kullanabileceğinden sorgulama yapmıyoruz.
        /*
        for (let i in this.d_addedModels) {
          if (this.d_addedModels[i].type === this.d_selectedStatisticalModel.type && this.d_addedModels[i].value === this.d_selectedStatisticalModel.value) {
            alert('Bu model ekli görünmektedir.');
            return;
          }
        }
        */
        this.d_addedModels.push(JSON.parse(JSON.stringify(this.d_selectedStatisticalModel)));
        if (this.d_selectedStatisticalModel['parameters']['diagnosis_main_group_code']) {
          if (this.d_statisticalParameterOptions['diagnosis_main_group_code'].length === 0) {
            this.f_getAllPatientRecordedDiagnosisList();
          }
          if (this.d_statisticalParameterOptions['hospital_list'].length === 0) {
            this.f_getHospitalGroup(this.d_selectedStatisticalModel.type);
          }
        }
        // this.$forceUpdate();
        // this.f_showTrueFalse();
      }
    },
    'f_getAllPatientRecordedDiagnosisList': function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sistemde şu ana kadar kullanılmış olan tüm tanılar listeleniyor' } });
      PatientService.find_all_used_diagnosis()
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let icd_code in resp.data.result) {
              // subgrupta direkt push yapıyrouz. main grup için ise noktadan ayrıştırarak kontrol edip push yapıyoruz.
              this.d_statisticalParameterOptions['diagnosis_sub_group_code'].push(resp.data.result[icd_code]);
              let det = false;
              let main_group = '';
              if (icd_code.indexOf('.') === -1) { // c18 ise
                main_group = icd_code;
              } else { // örneğin c18.5
                main_group = icd_code.split('.')[0];
              }
              for (let i in this.d_statisticalParameterOptions['diagnosis_main_group_code']) {
                if (this.d_statisticalParameterOptions['diagnosis_main_group_code'][i].value === main_group) {
                  det = true;
                  break;
                }
              }
              if (!det) {
                let x = { 'label': main_group, 'value': main_group };
                this.d_statisticalParameterOptions['diagnosis_main_group_code'].push(x);
              }
            }
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    f_getHospitalGroup: function (stat_model_type) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hastane listesi yükleniyor' } });
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            if (stat_model_type === 'my_hospital_group') {
              this.d_hospitalGroupList = resp.data.result;
            }
            for (let hg in this.d_hospitalGroupList) {
              if (this.d_hospitalGroupList[hg]['value'] === this.user.hospital_group) {
                this.d_statisticalParameterOptions['hospital_list'] = this.d_hospitalGroupList[hg]['hospital_list'];
                break;
              }
            }
          } else {
            alert('Grup kaydı henüz yok.');
          }
        });
    },
    f_showTrueFalse: function () {
      this.d_showTable = false;
      setTimeout(function () {
        this.d_showTable = true;
        this.$forceUpdate();
      }.bind(this), 100)
    }
  },
  watch: {
    'wdmr_change.rule': function () {
      // this.f_ruleQueryTrueFalse();
    },
    'd_selectedWdmTypeData': function () {
      if (this.d_selectedWdmTypeData) {
        console.log(this.d_selectedWdmTypeData);
        let bucket = '';
        if (this.d_selectedWdmTypeData.group === 'wisdom') {
          bucket = 'option';
        } else {
          bucket = 'hospital';
        }
        this.show_wisdom_data_model = false;
        GlobalService.get_one_cb_document({ 'key': this.d_selectedWdmTypeData.key, 'bucket': bucket })
          .then(resp => {
            let data_type = this.d_selectedWdmTypeData.value;
            if (resp.data.status === 'success') {
              let option_data = resp.data.result;
              this.selected_option = {};
              this.selected_option[data_type] = option_data;
              for (let i in option_data.param_group.param_order) {
                this.prepare_data['data'][option_data.param_group.param_order[i]] = {};
              }
              this.show_wisdom_data_model = true;
            } else {
              alert('error ', resp.data.message);
            }
          });
      }
    },
    'd_selectedStatisticalModelGroup': function () {
      this.d_selectedStatisticalModel = '';
    }
  }
};

</script>

<style>
.parameter-design-full-screen {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  margin-bottom: 0px;
}

.not-parameter-design-full-screen {
  width: 100%;
  height: 100%;
  border: solid 1px #20a8d8;
  /*background-color: rgba(0, 0, 0, .5);*/
  display: table;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

